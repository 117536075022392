import { Chart, registerables } from "chart.js";
import { IndexDataApi, MakeDataApi, ModelDataApi } from "./lib-api";
import { apiConfiguration } from "./util/api-configuration";

Chart.register(...registerables);

declare const window: {
    nrplr: typeof allTypeFunctions;
};

const observe = (el: HTMLElement, callback: () => void) => {
    const graphObserver = new IntersectionObserver((changes) => {
        for (const change of changes) {
            if (change.isIntersecting) {
                callback();
                graphObserver.unobserve(change.target);
                graphObserver.disconnect();
            }
        }
    }, {});
    graphObserver.observe(el);
};

const indexApi = new IndexDataApi(apiConfiguration);
const makeApi = new MakeDataApi(apiConfiguration);
const modelApi = new ModelDataApi(apiConfiguration);
const pieFuncs = {
    showFuelTypesAsync: async (
        canvasId: string,
        make: string,
        model?: string
    ) => {
        var data;
        if (make && model) {
            data = await modelApi.fuelTypesByModelAsync({ make, model });
        } else if (make) {
            data = await makeApi.fuelTypesByMakeAsync({ make });
        } else {
            data = await indexApi.fuelTypesAsync();
        }

        var canvas = document.getElementById(canvasId) as HTMLCanvasElement;

        observe(canvas, () => {
            var ctx = canvas.getContext("2d");
            var pieChart = new Chart(ctx, {
                type: "pie",
                data: { labels: data.labels, datasets: data.dataSets },
                options: {
                    responsive: true,
                    plugins: {
                        legend: {
                            position: "top",
                        },
                    },
                },
            });
        });
    },
    showVehicleTypesAsync: async (
        canvasId: string,
        make: string,
        model?: string
    ) => {
        var data;
        if (make && model) {
            data = await modelApi.vehicleTypesByModelAsync({ make, model });
        } else if (make) {
            data = await makeApi.vehicleTypesByMakeAsync({ make });
        } else {
            data = await indexApi.vehicleTypesAsync();
        }

        var canvas = document.getElementById(canvasId) as HTMLCanvasElement;

        observe(canvas, () => {
            var ctx = canvas.getContext("2d");

            var pieChart = new Chart(ctx, {
                type: "pie",
                data: { labels: data.labels, datasets: data.dataSets },
                options: {
                    responsive: true,
                    plugins: {
                        legend: {
                            position: "top",
                        },
                    },
                },
            });
        });
    },
};
const barFuncs = {
    showModelsByYearAsync: async (
        canvasId: string,
        make?: string,
        model?: string
    ) => {
        var data;
        if (make && model) {
            data = await modelApi.modelPopulationByYearGraphAsync({ make, model });
        } else if (make) {
            data = await makeApi.makePopulationByYearGraphAsync({ make });
        } else {
            data = await indexApi.populationByYearGraphAsync();
        }

        var canvas = document.getElementById(canvasId) as HTMLCanvasElement;

        observe(canvas, () => {
            var ctx = canvas.getContext("2d");

            var barChart = new Chart(ctx, {
                type: "bar",
                data: { labels: data.labels, datasets: data.dataSets },
                options: {
                    responsive: true,
                    plugins: {
                        legend: {
                            position: "top",
                        },
                    },
                },
            });
        });
    },
    showRegisteredAndImportedAsync: async (
        canvasId: string,
        make: string,
        model?: string
    ) => {
        const data = await makeApi.makeRegisteredAndImportedAsync({ make });

        var canvas = document.getElementById(canvasId) as HTMLCanvasElement;

        observe(canvas, () => {
            var ctx = canvas.getContext("2d");

            var barChart = new Chart(ctx, {
                type: "bar",
                data: { labels: data.labels, datasets: data.dataSets },
                options: {
                    responsive: true,
                    maintainAspectRatio: false,
                    plugins: {
                        legend: {
                            position: "top",
                        },
                    },
                },
            });
        });
    },
    showColorsByModelAsync: async (
        canvasId: string,
        make?: string,
        model?: string
    ) => {
        var data;
        if (make && model) {
            data = await modelApi.colorsByModelAsync({ make, model });
        } else {
            data = await makeApi.colorsByMakeAsync({ make });
        }


        var canvas = document.getElementById(canvasId) as HTMLCanvasElement;

        observe(canvas, () => {
            var ctx = canvas.getContext("2d");

            var barChart = new Chart(ctx, {
                type: "bar",
                data: { labels: data.labels, datasets: data.dataSets },
                options: {
                    indexAxis: "y",
                    responsive: true,
                    maintainAspectRatio: false,
                    plugins: {
                        legend: {
                            position: "top",
                        },
                    },
                },
            });
        });
    },
};

const lineFuncs = {
    showRegisteredByYearAsync: async (
        canvasId: string,
        make?: string,
        model?: string
    ) => {
        var data;
        const temp: string[] = [];
        if (make && model) {
            data = await modelApi.modelRegisteredByYearAsync({ make, model });
        } else if (make) {
            data = await makeApi.makeRegisteredByYearAsync({ make, requestBody: temp });
        } else {
            data = await indexApi.registeredByYearAsync({ requestBody: temp });
        }

        var canvas = document.getElementById(canvasId) as HTMLCanvasElement;

        observe(canvas, () => {
            var ctx = canvas.getContext("2d");

            var lineChart = new Chart(ctx, {
                type: "line",
                data: { labels: data.labels, datasets: data.dataSets },

                options: {
                    responsive: true,
                    line: {
                        datasets: {
                            tension: 0,
                            stepped: false,
                            borderDash: [],
                        },
                    },
                    plugins: {
                        legend: {
                            position: "top",
                        },

                    },
                },
            });

            if (!model) {
                const selecteds: string[] = [];
                document
                    .getElementById(canvasId + "-btn")
                    .addEventListener("click", async () => {
                        if (selecteds.length < 4) {
                            var inp = document.getElementById("input") as HTMLInputElement
                            var inpVal = inp.value

                            if (!selecteds.includes(inpVal)) {
                                selecteds.push(inpVal);

                                const deleteButton = document.createElement("button")
                                deleteButton.innerHTML = "X"
                                deleteButton.style.color = "white"
                                deleteButton.style.backgroundColor = "red"

                                deleteButton.onclick = async function () {

                                    selecteds.splice(selecteds.indexOf(inpVal), 1);
                                    console.log(selecteds)
                                    document.getElementById(canvasId + inpVal).remove()
                                    var newData = await makeApi.makeRegisteredByYearAsync({ make, requestBody: selecteds });
                                    lineChart.data = { labels: newData.labels, datasets: newData.dataSets }
                                    lineChart.update();
                                }

                                const selectedDiv = document.createElement("div");
                                selectedDiv.innerHTML = inpVal + " ";
                                selectedDiv.id = canvasId + inpVal
                                selectedDiv.style.display = "inline"
                                selectedDiv.style.marginRight = "15px"

                                selectedDiv.appendChild(deleteButton)

                                document
                                    .getElementById(canvasId + "selectedsdiv")
                                    .appendChild(selectedDiv);

                                if (make) {
                                    var newData = await makeApi.makeRegisteredByYearAsync({ make, requestBody: selecteds });
                                } else if (make && model) {
                                } else {
                                    var newData = await indexApi.registeredByYearAsync({ requestBody: selecteds });
                                }
                                lineChart.data = { labels: newData.labels, datasets: newData.dataSets }
                                lineChart.update();
                            }
                        } else {
                            alert("Maks 4 modeller, fjern en model for at tilføje en anden")
                        }
                    });
            }
        });
    },
    showBilhandelPriceAsync: async (
        canvasId: string,
        make?: string,
        model?: string
    ) => {
        var data;
        if (make && model) {
            data = await modelApi.modelBilhandelPriceAsync({ model });
        } else if (make) {
            data = await makeApi.makeBilhandelPriceAsync({ make });
        } else {
        }

        var canvas = document.getElementById(canvasId) as HTMLCanvasElement;

        observe(canvas, () => {
            var ctx = canvas.getContext("2d");

            var lineChart = new Chart(ctx, {
                type: "line",
                data: { labels: data.labels, datasets: data.dataSets },

                options: {
                    responsive: true,
                    plugins: {
                        legend: {
                            position: "top",
                        },

                    },
                },
            });
        });
    },
    showAvgDaysToSaleAsync: async (
        canvasId: string,
        make?: string,
        model?: string
    ) => {
        var data;
        if (make && model) {
            data = await modelApi.modelAvgDaysToSaleAsync({ model });
        } else if (make) {
            data = await makeApi.makeAvgDaysToSaleAsync({ make });
        } else {
        }

        var canvas = document.getElementById(canvasId) as HTMLCanvasElement;

        observe(canvas, () => {
            var ctx = canvas.getContext("2d");

            var lineChart = new Chart(ctx, {
                type: "line",
                data: { labels: data.labels, datasets: data.dataSets },

                options: {
                    responsive: true,
                    plugins: {
                        legend: {
                            position: "top",
                        },

                    },
                },
            });
        });
    },
    showModelCreatedAmountAsync: async (
        canvasId: string,
        make?: string,
        model?: string
    ) => {
        var data;
        if (make && model) {
            data = await modelApi.modelCreatedAmountAsync({ model });
        } else if (make) {
            data = await makeApi.makeCreatedAmountAsync({ make });
        } else {
        }

        var canvas = document.getElementById(canvasId) as HTMLCanvasElement;

        observe(canvas, () => {
            var ctx = canvas.getContext("2d");

            var lineChart = new Chart(ctx, {
                type: "line",
                data: { labels: data.labels, datasets: data.dataSets },

                options: {
                    responsive: true,
                    plugins: {
                        legend: {
                            position: "top",
                        },

                    },
                },
            });
        });
    },
};

const allFunctions = {
    ...pieFuncs,
    ...barFuncs,
    ...lineFuncs,
};

const allTypeFunctions = {
    pie: pieFuncs,
    line: lineFuncs,
    bars: barFuncs,
};

window.nrplr = allTypeFunctions;

export { };
