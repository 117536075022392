/* tslint:disable */
/* eslint-disable */
/**
 * NummerpladeregisterAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    BarGraphDataPointsGraphViewModel,
    BarGraphDataPointsGraphViewModelFromJSON,
    BarGraphDataPointsGraphViewModelToJSON,
    LineGraphDataPointsGraphViewModel,
    LineGraphDataPointsGraphViewModelFromJSON,
    LineGraphDataPointsGraphViewModelToJSON,
    PieGraphDataPointsGraphViewModel,
    PieGraphDataPointsGraphViewModelFromJSON,
    PieGraphDataPointsGraphViewModelToJSON,
} from '../models';

export interface ColorsByMakeAsyncRequest {
    make?: string;
}

export interface ColorsByMakeTableAsyncRequest {
    make?: string;
}

export interface FuelTypesByMakeAsyncRequest {
    make?: string;
}

export interface MakeAvgDaysToSaleAsyncRequest {
    make?: string;
}

export interface MakeBilhandelPriceAsyncRequest {
    make?: string;
}

export interface MakeCreatedAmountAsyncRequest {
    make?: string;
}

export interface MakePopulationByYearGraphAsyncRequest {
    make?: string;
}

export interface MakeRegisteredAndImportedAsyncRequest {
    make?: string;
}

export interface MakeRegisteredByYearAsyncRequest {
    make?: string;
    requestBody?: Array<string>;
}

export interface VehicleTypesByMakeAsyncRequest {
    make?: string;
}

/**
 * 
 */
export class MakeDataApi extends runtime.BaseAPI {

    /**
     */
    async colorsByMakeAsyncRaw(requestParameters: ColorsByMakeAsyncRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<BarGraphDataPointsGraphViewModel>> {
        const queryParameters: any = {};

        if (requestParameters.make !== undefined) {
            queryParameters['make'] = requestParameters.make;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/make/colorsbymake`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BarGraphDataPointsGraphViewModelFromJSON(jsonValue));
    }

    /**
     */
    async colorsByMakeAsync(requestParameters: ColorsByMakeAsyncRequest, initOverrides?: RequestInit): Promise<BarGraphDataPointsGraphViewModel> {
        const response = await this.colorsByMakeAsyncRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async colorsByMakeTableAsyncRaw(requestParameters: ColorsByMakeTableAsyncRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<{ [key: string]: object; }>> {
        const queryParameters: any = {};

        if (requestParameters.make !== undefined) {
            queryParameters['make'] = requestParameters.make;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/make/colorsbymaketable`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async colorsByMakeTableAsync(requestParameters: ColorsByMakeTableAsyncRequest, initOverrides?: RequestInit): Promise<{ [key: string]: object; }> {
        const response = await this.colorsByMakeTableAsyncRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async fuelTypesByMakeAsyncRaw(requestParameters: FuelTypesByMakeAsyncRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PieGraphDataPointsGraphViewModel>> {
        const queryParameters: any = {};

        if (requestParameters.make !== undefined) {
            queryParameters['make'] = requestParameters.make;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/make/fueltypesbymake`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PieGraphDataPointsGraphViewModelFromJSON(jsonValue));
    }

    /**
     */
    async fuelTypesByMakeAsync(requestParameters: FuelTypesByMakeAsyncRequest, initOverrides?: RequestInit): Promise<PieGraphDataPointsGraphViewModel> {
        const response = await this.fuelTypesByMakeAsyncRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async makeAvgDaysToSaleAsyncRaw(requestParameters: MakeAvgDaysToSaleAsyncRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<LineGraphDataPointsGraphViewModel>> {
        const queryParameters: any = {};

        if (requestParameters.make !== undefined) {
            queryParameters['make'] = requestParameters.make;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/make/makeavgdaystosale`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LineGraphDataPointsGraphViewModelFromJSON(jsonValue));
    }

    /**
     */
    async makeAvgDaysToSaleAsync(requestParameters: MakeAvgDaysToSaleAsyncRequest, initOverrides?: RequestInit): Promise<LineGraphDataPointsGraphViewModel> {
        const response = await this.makeAvgDaysToSaleAsyncRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async makeBilhandelPriceAsyncRaw(requestParameters: MakeBilhandelPriceAsyncRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<LineGraphDataPointsGraphViewModel>> {
        const queryParameters: any = {};

        if (requestParameters.make !== undefined) {
            queryParameters['make'] = requestParameters.make;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/make/makebilhandelprice`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LineGraphDataPointsGraphViewModelFromJSON(jsonValue));
    }

    /**
     */
    async makeBilhandelPriceAsync(requestParameters: MakeBilhandelPriceAsyncRequest, initOverrides?: RequestInit): Promise<LineGraphDataPointsGraphViewModel> {
        const response = await this.makeBilhandelPriceAsyncRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async makeCreatedAmountAsyncRaw(requestParameters: MakeCreatedAmountAsyncRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<LineGraphDataPointsGraphViewModel>> {
        const queryParameters: any = {};

        if (requestParameters.make !== undefined) {
            queryParameters['make'] = requestParameters.make;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/make/makecreatedamount`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LineGraphDataPointsGraphViewModelFromJSON(jsonValue));
    }

    /**
     */
    async makeCreatedAmountAsync(requestParameters: MakeCreatedAmountAsyncRequest, initOverrides?: RequestInit): Promise<LineGraphDataPointsGraphViewModel> {
        const response = await this.makeCreatedAmountAsyncRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async makePopulationByYearGraphAsyncRaw(requestParameters: MakePopulationByYearGraphAsyncRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<BarGraphDataPointsGraphViewModel>> {
        const queryParameters: any = {};

        if (requestParameters.make !== undefined) {
            queryParameters['make'] = requestParameters.make;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/make/makepopulationbyyear`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BarGraphDataPointsGraphViewModelFromJSON(jsonValue));
    }

    /**
     */
    async makePopulationByYearGraphAsync(requestParameters: MakePopulationByYearGraphAsyncRequest, initOverrides?: RequestInit): Promise<BarGraphDataPointsGraphViewModel> {
        const response = await this.makePopulationByYearGraphAsyncRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async makeRegisteredAndImportedAsyncRaw(requestParameters: MakeRegisteredAndImportedAsyncRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<BarGraphDataPointsGraphViewModel>> {
        const queryParameters: any = {};

        if (requestParameters.make !== undefined) {
            queryParameters['make'] = requestParameters.make;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/make/makeregisteredandimported`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BarGraphDataPointsGraphViewModelFromJSON(jsonValue));
    }

    /**
     */
    async makeRegisteredAndImportedAsync(requestParameters: MakeRegisteredAndImportedAsyncRequest, initOverrides?: RequestInit): Promise<BarGraphDataPointsGraphViewModel> {
        const response = await this.makeRegisteredAndImportedAsyncRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async makeRegisteredByYearAsyncRaw(requestParameters: MakeRegisteredByYearAsyncRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<LineGraphDataPointsGraphViewModel>> {
        const queryParameters: any = {};

        if (requestParameters.make !== undefined) {
            queryParameters['make'] = requestParameters.make;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/make/makeregisteredbyyear`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.requestBody,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LineGraphDataPointsGraphViewModelFromJSON(jsonValue));
    }

    /**
     */
    async makeRegisteredByYearAsync(requestParameters: MakeRegisteredByYearAsyncRequest, initOverrides?: RequestInit): Promise<LineGraphDataPointsGraphViewModel> {
        const response = await this.makeRegisteredByYearAsyncRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async vehicleTypesByMakeAsyncRaw(requestParameters: VehicleTypesByMakeAsyncRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PieGraphDataPointsGraphViewModel>> {
        const queryParameters: any = {};

        if (requestParameters.make !== undefined) {
            queryParameters['make'] = requestParameters.make;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/make/vehicletypesbymake`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PieGraphDataPointsGraphViewModelFromJSON(jsonValue));
    }

    /**
     */
    async vehicleTypesByMakeAsync(requestParameters: VehicleTypesByMakeAsyncRequest, initOverrides?: RequestInit): Promise<PieGraphDataPointsGraphViewModel> {
        const response = await this.vehicleTypesByMakeAsyncRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
