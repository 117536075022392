/* tslint:disable */
/* eslint-disable */
/**
 * NummerpladeregisterAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    LineGraphDataPoints,
    LineGraphDataPointsFromJSON,
    LineGraphDataPointsFromJSONTyped,
    LineGraphDataPointsToJSON,
} from './';

/**
 * 
 * @export
 * @interface LineGraphDataPointsGraphViewModel
 */
export interface LineGraphDataPointsGraphViewModel {
    /**
     * 
     * @type {Array<string>}
     * @memberof LineGraphDataPointsGraphViewModel
     */
    labels?: Array<string> | null;
    /**
     * 
     * @type {Array<LineGraphDataPoints>}
     * @memberof LineGraphDataPointsGraphViewModel
     */
    dataSets?: Array<LineGraphDataPoints> | null;
}

export function LineGraphDataPointsGraphViewModelFromJSON(json: any): LineGraphDataPointsGraphViewModel {
    return LineGraphDataPointsGraphViewModelFromJSONTyped(json, false);
}

export function LineGraphDataPointsGraphViewModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): LineGraphDataPointsGraphViewModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'labels': !exists(json, 'labels') ? undefined : json['labels'],
        'dataSets': !exists(json, 'dataSets') ? undefined : (json['dataSets'] === null ? null : (json['dataSets'] as Array<any>).map(LineGraphDataPointsFromJSON)),
    };
}

export function LineGraphDataPointsGraphViewModelToJSON(value?: LineGraphDataPointsGraphViewModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'labels': value.labels,
        'dataSets': value.dataSets === undefined ? undefined : (value.dataSets === null ? null : (value.dataSets as Array<any>).map(LineGraphDataPointsToJSON)),
    };
}


