/* tslint:disable */
/* eslint-disable */
/**
 * NummerpladeregisterAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    BarGraphDataPointsGraphViewModel,
    BarGraphDataPointsGraphViewModelFromJSON,
    BarGraphDataPointsGraphViewModelToJSON,
    LineGraphDataPointsGraphViewModel,
    LineGraphDataPointsGraphViewModelFromJSON,
    LineGraphDataPointsGraphViewModelToJSON,
    PieGraphDataPointsGraphViewModel,
    PieGraphDataPointsGraphViewModelFromJSON,
    PieGraphDataPointsGraphViewModelToJSON,
} from '../models';

export interface RegisteredByYearAsyncRequest {
    requestBody?: Array<string>;
}

/**
 * 
 */
export class IndexDataApi extends runtime.BaseAPI {

    /**
     */
    async fuelTypesAsyncRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<PieGraphDataPointsGraphViewModel>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/index/fueltypes`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PieGraphDataPointsGraphViewModelFromJSON(jsonValue));
    }

    /**
     */
    async fuelTypesAsync(initOverrides?: RequestInit): Promise<PieGraphDataPointsGraphViewModel> {
        const response = await this.fuelTypesAsyncRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async populationByYearGraphAsyncRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<BarGraphDataPointsGraphViewModel>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/index/populationbyyear`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BarGraphDataPointsGraphViewModelFromJSON(jsonValue));
    }

    /**
     */
    async populationByYearGraphAsync(initOverrides?: RequestInit): Promise<BarGraphDataPointsGraphViewModel> {
        const response = await this.populationByYearGraphAsyncRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async registeredByYearAsyncRaw(requestParameters: RegisteredByYearAsyncRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<LineGraphDataPointsGraphViewModel>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/index/registeredbyyear`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.requestBody,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LineGraphDataPointsGraphViewModelFromJSON(jsonValue));
    }

    /**
     */
    async registeredByYearAsync(requestParameters: RegisteredByYearAsyncRequest, initOverrides?: RequestInit): Promise<LineGraphDataPointsGraphViewModel> {
        const response = await this.registeredByYearAsyncRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async vehicleTypesAsyncRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<PieGraphDataPointsGraphViewModel>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/index/vehicletypes`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PieGraphDataPointsGraphViewModelFromJSON(jsonValue));
    }

    /**
     */
    async vehicleTypesAsync(initOverrides?: RequestInit): Promise<PieGraphDataPointsGraphViewModel> {
        const response = await this.vehicleTypesAsyncRaw(initOverrides);
        return await response.value();
    }

}
