/* tslint:disable */
/* eslint-disable */
/**
 * NummerpladeregisterAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    BarGraphDataPointsGraphViewModel,
    BarGraphDataPointsGraphViewModelFromJSON,
    BarGraphDataPointsGraphViewModelToJSON,
    LineGraphDataPointsGraphViewModel,
    LineGraphDataPointsGraphViewModelFromJSON,
    LineGraphDataPointsGraphViewModelToJSON,
    PieGraphDataPointsGraphViewModel,
    PieGraphDataPointsGraphViewModelFromJSON,
    PieGraphDataPointsGraphViewModelToJSON,
} from '../models';

export interface ColorsByModelAsyncRequest {
    make?: string;
    model?: string;
}

export interface FuelTypesByModelAsyncRequest {
    make?: string;
    model?: string;
}

export interface ModelAvgDaysToSaleAsyncRequest {
    model?: string;
}

export interface ModelBilhandelPriceAsyncRequest {
    model?: string;
}

export interface ModelCreatedAmountAsyncRequest {
    model?: string;
}

export interface ModelPopulationByYearGraphAsyncRequest {
    make?: string;
    model?: string;
}

export interface ModelRegisteredByYearAsyncRequest {
    make?: string;
    model?: string;
}

export interface VehicleTypesByModelAsyncRequest {
    make?: string;
    model?: string;
}

/**
 * 
 */
export class ModelDataApi extends runtime.BaseAPI {

    /**
     */
    async colorsByModelAsyncRaw(requestParameters: ColorsByModelAsyncRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<BarGraphDataPointsGraphViewModel>> {
        const queryParameters: any = {};

        if (requestParameters.make !== undefined) {
            queryParameters['make'] = requestParameters.make;
        }

        if (requestParameters.model !== undefined) {
            queryParameters['model'] = requestParameters.model;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/model/colorsbymodel`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BarGraphDataPointsGraphViewModelFromJSON(jsonValue));
    }

    /**
     */
    async colorsByModelAsync(requestParameters: ColorsByModelAsyncRequest, initOverrides?: RequestInit): Promise<BarGraphDataPointsGraphViewModel> {
        const response = await this.colorsByModelAsyncRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async fuelTypesByModelAsyncRaw(requestParameters: FuelTypesByModelAsyncRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PieGraphDataPointsGraphViewModel>> {
        const queryParameters: any = {};

        if (requestParameters.make !== undefined) {
            queryParameters['make'] = requestParameters.make;
        }

        if (requestParameters.model !== undefined) {
            queryParameters['model'] = requestParameters.model;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/model/fueltypesbymodel`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PieGraphDataPointsGraphViewModelFromJSON(jsonValue));
    }

    /**
     */
    async fuelTypesByModelAsync(requestParameters: FuelTypesByModelAsyncRequest, initOverrides?: RequestInit): Promise<PieGraphDataPointsGraphViewModel> {
        const response = await this.fuelTypesByModelAsyncRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async modelAvgDaysToSaleAsyncRaw(requestParameters: ModelAvgDaysToSaleAsyncRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<LineGraphDataPointsGraphViewModel>> {
        const queryParameters: any = {};

        if (requestParameters.model !== undefined) {
            queryParameters['model'] = requestParameters.model;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/model/modelavgdaystosale`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LineGraphDataPointsGraphViewModelFromJSON(jsonValue));
    }

    /**
     */
    async modelAvgDaysToSaleAsync(requestParameters: ModelAvgDaysToSaleAsyncRequest, initOverrides?: RequestInit): Promise<LineGraphDataPointsGraphViewModel> {
        const response = await this.modelAvgDaysToSaleAsyncRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async modelBilhandelPriceAsyncRaw(requestParameters: ModelBilhandelPriceAsyncRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<LineGraphDataPointsGraphViewModel>> {
        const queryParameters: any = {};

        if (requestParameters.model !== undefined) {
            queryParameters['model'] = requestParameters.model;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/model/modelbilhandelprice`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LineGraphDataPointsGraphViewModelFromJSON(jsonValue));
    }

    /**
     */
    async modelBilhandelPriceAsync(requestParameters: ModelBilhandelPriceAsyncRequest, initOverrides?: RequestInit): Promise<LineGraphDataPointsGraphViewModel> {
        const response = await this.modelBilhandelPriceAsyncRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async modelCreatedAmountAsyncRaw(requestParameters: ModelCreatedAmountAsyncRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<LineGraphDataPointsGraphViewModel>> {
        const queryParameters: any = {};

        if (requestParameters.model !== undefined) {
            queryParameters['model'] = requestParameters.model;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/model/modelcreatedamount`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LineGraphDataPointsGraphViewModelFromJSON(jsonValue));
    }

    /**
     */
    async modelCreatedAmountAsync(requestParameters: ModelCreatedAmountAsyncRequest, initOverrides?: RequestInit): Promise<LineGraphDataPointsGraphViewModel> {
        const response = await this.modelCreatedAmountAsyncRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async modelPopulationByYearGraphAsyncRaw(requestParameters: ModelPopulationByYearGraphAsyncRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<BarGraphDataPointsGraphViewModel>> {
        const queryParameters: any = {};

        if (requestParameters.make !== undefined) {
            queryParameters['make'] = requestParameters.make;
        }

        if (requestParameters.model !== undefined) {
            queryParameters['model'] = requestParameters.model;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/model/modelpopulationbyyear`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BarGraphDataPointsGraphViewModelFromJSON(jsonValue));
    }

    /**
     */
    async modelPopulationByYearGraphAsync(requestParameters: ModelPopulationByYearGraphAsyncRequest, initOverrides?: RequestInit): Promise<BarGraphDataPointsGraphViewModel> {
        const response = await this.modelPopulationByYearGraphAsyncRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async modelRegisteredByYearAsyncRaw(requestParameters: ModelRegisteredByYearAsyncRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<LineGraphDataPointsGraphViewModel>> {
        const queryParameters: any = {};

        if (requestParameters.make !== undefined) {
            queryParameters['make'] = requestParameters.make;
        }

        if (requestParameters.model !== undefined) {
            queryParameters['model'] = requestParameters.model;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/model/modelregisteredbyyear`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LineGraphDataPointsGraphViewModelFromJSON(jsonValue));
    }

    /**
     */
    async modelRegisteredByYearAsync(requestParameters: ModelRegisteredByYearAsyncRequest, initOverrides?: RequestInit): Promise<LineGraphDataPointsGraphViewModel> {
        const response = await this.modelRegisteredByYearAsyncRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async vehicleTypesByModelAsyncRaw(requestParameters: VehicleTypesByModelAsyncRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PieGraphDataPointsGraphViewModel>> {
        const queryParameters: any = {};

        if (requestParameters.make !== undefined) {
            queryParameters['make'] = requestParameters.make;
        }

        if (requestParameters.model !== undefined) {
            queryParameters['model'] = requestParameters.model;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/model/vehicletypesbymodel`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PieGraphDataPointsGraphViewModelFromJSON(jsonValue));
    }

    /**
     */
    async vehicleTypesByModelAsync(requestParameters: VehicleTypesByModelAsyncRequest, initOverrides?: RequestInit): Promise<PieGraphDataPointsGraphViewModel> {
        const response = await this.vehicleTypesByModelAsyncRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
