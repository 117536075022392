/* tslint:disable */
/* eslint-disable */
/**
 * NummerpladeregisterAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    BarGraphDataPoints,
    BarGraphDataPointsFromJSON,
    BarGraphDataPointsFromJSONTyped,
    BarGraphDataPointsToJSON,
} from './';

/**
 * 
 * @export
 * @interface BarGraphDataPointsGraphViewModel
 */
export interface BarGraphDataPointsGraphViewModel {
    /**
     * 
     * @type {Array<string>}
     * @memberof BarGraphDataPointsGraphViewModel
     */
    labels?: Array<string> | null;
    /**
     * 
     * @type {Array<BarGraphDataPoints>}
     * @memberof BarGraphDataPointsGraphViewModel
     */
    dataSets?: Array<BarGraphDataPoints> | null;
}

export function BarGraphDataPointsGraphViewModelFromJSON(json: any): BarGraphDataPointsGraphViewModel {
    return BarGraphDataPointsGraphViewModelFromJSONTyped(json, false);
}

export function BarGraphDataPointsGraphViewModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): BarGraphDataPointsGraphViewModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'labels': !exists(json, 'labels') ? undefined : json['labels'],
        'dataSets': !exists(json, 'dataSets') ? undefined : (json['dataSets'] === null ? null : (json['dataSets'] as Array<any>).map(BarGraphDataPointsFromJSON)),
    };
}

export function BarGraphDataPointsGraphViewModelToJSON(value?: BarGraphDataPointsGraphViewModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'labels': value.labels,
        'dataSets': value.dataSets === undefined ? undefined : (value.dataSets === null ? null : (value.dataSets as Array<any>).map(BarGraphDataPointsToJSON)),
    };
}


